import MarketingLayout from '$components/MarketingLayout';
import MailchimpSignup from '$components/MailchimpSignup';
import Hero from '$components/Hero';
import { Flex, Col } from '$components/Flex';
import MarketingSection from '$components/MarketingSection';
import MarketingDivide from '$components/MarketingDivide';
import Contributions from '$components/MarketingContributions';
import Contribution from '$components/MarketingContribution';
import chat from '$images/contributions/chat2.png';
import code from '$images/contributions/code2.png';
import docs from '$images/contributions/docs.svg';
import sponsor from '$images/contributions/sponsor.png';
import stories from '$images/contributions/stories2.png';
import talks from '$images/contributions/talks3.png';
import write from '$images/contributions/write2.png';
import React from 'react';
export default {
  MarketingLayout,
  MailchimpSignup,
  Hero,
  Flex,
  Col,
  MarketingSection,
  MarketingDivide,
  Contributions,
  Contribution,
  chat,
  code,
  docs,
  sponsor,
  stories,
  talks,
  write,
  React
};