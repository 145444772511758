export default {
  nodes: "transparent",
  white: "white",
  dark: "#1b3956",
  medium: "#1f4c77",
  light: "#4680B4",
};

export const TEXT = {
  node: "black",
  //white: "black",
  white: "#1b3956",
  dark: "white",
  medium: "white",
  light: "white",
  // #464646
};
