import MarketingLayout from '$components/MarketingLayout';
import Hero from '$components/Hero';
import MarketingSection from '$components/MarketingSection';
import MarketingDivide from '$components/MarketingDivide';
import MarketingBullets from '$components/MarketingBullets';
import TeamBio from '$components/MarketingTeamBio';
import React from 'react';
export default {
  MarketingLayout,
  Hero,
  MarketingSection,
  MarketingDivide,
  MarketingBullets,
  TeamBio,
  React
};