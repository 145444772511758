import MarketingLayout from '$components/MarketingLayout';
import MailchimpSignup from '$components/MailchimpSignup';
import Hero from '$components/Hero';
import { Flex, Col } from '$components/Flex';
import MarketingSection from '$components/MarketingSection';
import MarketingDivide from '$components/MarketingDivide';
import MarketingCodebox from '$components/MarketingCodebox';
import MarketingBullets from '$components/MarketingBullets';
import postgresPostgraphileGraphql from '$images/postgres_postgraphile_graphql.png';
import Testimonial from '$components/MarketingTestimonial';
import React from 'react';
export default {
  MarketingLayout,
  MailchimpSignup,
  Hero,
  Flex,
  Col,
  MarketingSection,
  MarketingDivide,
  MarketingCodebox,
  MarketingBullets,
  postgresPostgraphileGraphql,
  Testimonial,
  React
};